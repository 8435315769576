import React, { useEffect, useState } from 'react';
import GraphiQL from 'graphiql';
import 'graphiql/graphiql.min.css';
import { buildSchema, getIntrospectionQuery, GraphQLSchema } from 'graphql';
import schemaUrl from './schema.graphql';

const DEFAULT_QUERY = `
# Welcome to the Compass GraphQL Playground
#
# This is a tool for exploring the Compass GraphQL API.
#
# Type queries into this side of the screen, and you will see intelligent
# typeaheads aware of the current GraphQL type schema and live syntax and
# validation errors highlighted within the text.
#
# GraphQL queries typically start with a "{" character. Lines that start
# with a # are ignored.
#
# An example GraphQL query might look like:
#
#     {
#       collection(where: {id: "0xda2686fd32c6b74d55605cfb48bef331771e7fc6"}) {
#         id
#         name
#         listedFloor
#         topOffer
#       }
#     }
#
# Keyboard shortcuts:
#
#   Prettify query:  Shift-Ctrl-P (or press the prettify button)
#
#  Merge fragments:  Shift-Ctrl-M (or press the merge button)
#
#        Run Query:  Ctrl-Enter (or press the play button)
#
#    Auto Complete:  Ctrl-Space (or just start typing)
#
`;

const App = () => {
  console.log('building', schemaUrl)
  
  const [schema, setSchema] = useState<GraphQLSchema>();
  useEffect(()=>{
    fetch(schemaUrl)
      .then(response => response.text())
      .then((res) => {
        console.log('got schema', res);
        getIntrospectionQuery();
        setSchema(buildSchema(res));
      });
  }, []);
  return (
    schema ? 
  <GraphiQL
  schema={schema}
  defaultEditorToolsVisibility={true}
  introspectionQueryName="IntrospectionQuery"
  dangerouslyAssumeSchemaIsValid={true}
  defaultQuery={DEFAULT_QUERY}
    fetcher={async graphQLParams => {
      console.log('fetching', graphQLParams)
      const data = await fetch(
        'https://api.compass.art/graphql',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(graphQLParams),
          credentials: 'same-origin',
        },
      );
      return data.json().catch(() => data.text());
    }}
  /> : <div>loading</div>
);}

export default App;
